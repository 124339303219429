import {
  blueprintShapelyScript,
  BUFFER_TYPE,
  GEOMETRY_TYPE,
  GEOMETRY_TYPES,
  GEOMETRY_TYPES_FEATURE,
  HighlightFeatureStyleFn,
  MAP_TYPE,
  PX_TO_MM_CONSTANT
} from 'woodpecker';
import { Feature as olFeature, Map as olMap, MapBrowserEvent } from 'ol';
import { GEO_JSON, isValid } from 'macaw';
import { Extent, containsCoordinate, containsExtent } from 'ol/extent';
import { showToast } from 'ui';
import { Circle, Stroke, Style } from 'ol/style';
import { Feature as turfFeature, Properties, buffer, difference, Polygon, MultiPolygon, Position } from '@turf/turf';
import { Geometry, LineString, Polygon as olPolygon } from 'ol/geom';
import VectorImageLayer from 'ol/layer/VectorImage';
import booleanIntersects from '@turf/boolean-intersects';
import VectorLayer from 'ol/layer/Vector';
import olVectorSource from 'ol/source/Vector';
import { Select } from 'ol/interaction';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { Coordinate } from 'ol/coordinate';
import OverlapWorker from 'worker-loader!./overlapWorker.ts';
import { CustomVectorSource as VectorSource } from '../js-layer/mapLayer/openlayerPolyfills';
import { mergeFeatAndGeomTags } from './tagging';
import { FilterQuery, taggingActions, useTags } from '../store/tagsStore';
import { FilterBy, LogicalOperation } from '../constants';
import { PARCEL } from '../hooks/tools/helpers/constants';
import { mapObj } from '../js-layer/mapLayer/mapInit';
import { globalStore } from '../js-layer/utilityclasses/AppStoreListener';
import useMapStore, { Layer, TagsInfoType, Feature } from '../store/mapStore';
import asyncRun from '../hooks/tools/workers/py-worker';
import { TOOL_TYPE } from '../js-layer/toolLayer/constants';

export const highlightFeature = (feature: olFeature) => {
  feature.setStyle(HighlightFeatureStyleFn());
  setTimeout(() => {
    feature.setStyle();
  }, 8000);
};

export const highlightFeatureIfInvalid = function (feature: olFeature, showErrorMsg = true) {
  if (!isValid(feature)) {
    highlightFeature(feature);
    return false;
  }
  return true;
};

export const calculateBPLength = ({ dpi, scale, length }: { dpi: number; scale: number; length: number }) => {
  const px_to_mm = 25.4 / dpi;
  return length * scale * px_to_mm * 0.001;
};

export const calculateBPArea = ({ dpi, scale, area }: { dpi: number; scale: number; area: number }) => {
  const px_to_mm = PX_TO_MM_CONSTANT / dpi;
  return area * scale * px_to_mm * 0.001 * (scale * px_to_mm * 0.001);
};

export const isOutOfExtent = (e: MapBrowserEvent<UIEvent>, map: olMap | null, showMsg = true): boolean => {
  if (mapObj?.map_type === MAP_TYPE.AERIAL) return false;
  if (!map) return true;
  const clickedCoordinate = e.coordinate;
  const width = map.get('PROJECTED_WIDTH');
  const height = map.get('PROJECTED_HEIGHT');
  // const extent = map.getAllLayers()[1].getSource().getImageExtent(); // In case we render static image
  // const extent = [0, -1 * PROJECTION_HEIGHT, PROJECTION_WIDTH, 0];

  let extent = null;
  try {
    // extent = map.getAllLayers()[0]?.getSource()?.getImageExtent();
    extent = [0, -1 * height, width, 0];
  } catch (error) {
    extent = null;
  }

  const outOfExtent = extent ? !containsCoordinate(extent, clickedCoordinate) : false;
  if (outOfExtent && showMsg) showToast("Feature can't go out of map extent", 'error');

  return outOfExtent;
};

export const FeatureisOutOfExtent = (featureExtent: Extent, map: olMap | null): boolean => {
  if (!map) return true;
  const width = map.get('PROJECTED_WIDTH');
  const height = map.get('PROJECTED_HEIGHT');
  const extent = [0, -1 * height, width, 0];

  const outOfExtent = !containsExtent(extent, featureExtent);
  if (outOfExtent) showToast("Feature can't go out of map extent", 'error');

  return outOfExtent;
};

export const appStore = useMapStore.getState();

export const makeOlFeature = (geom: Geometry, props: Record<string, any>) => {
  return new olFeature({
    geometry: geom,
    ...props
  });
};

export const getLayerType = (id: string) => {
  const { AppStore } = globalStore;
  const layer = AppStore?.layers?.get(id);
  return layer;
};

const convertFeatureGeometry = (drawnFeature: olFeature<Geometry>, selectedLayer: VectorImageLayer<VectorSource>) => {
  let geoms = [];
  const features: Array<any> = [];
  const geometry = drawnFeature.getGeometry()?.getType();
  const props = drawnFeature.getProperties();
  delete props.geometry;
  if (geometry === GEOMETRY_TYPES.MULTI_POINT) {
    geoms = drawnFeature.getGeometry()?.getPoints();
  } else if (geometry === GEOMETRY_TYPES.MULTI_LINESTRING) {
    geoms = drawnFeature.getGeometry()?.getLineStrings();
  } else if (geometry === GEOMETRY_TYPES.MULTI_POLYGON) {
    geoms = drawnFeature.getGeometry()?.getPolygons();
  }

  geoms.forEach((geom: Geometry) => {
    const feature = makeOlFeature(geom, props);
    features.push(feature);
  });

  if (features.length) {
    selectedLayer?.getSource()?.addFeatures(features);
    selectedLayer?.getSource()?.removeFeature(drawnFeature);
  }
};

const removeFeatureFromLayer = (feature: olFeature<Geometry>) => {
  const layer = mapObj.getLayerById(feature.get('layerId'));
  if (layer) {
    layer.getSource().removeFeature(feature);
  }
};

const CHUNK_SIZE = 25;
let worker: Worker | null = null;

export const initOverlapWorker = () => {
  if (!worker) {
    worker = new OverlapWorker();
  }
  return worker;
};

export const getFeaturesAfterOverlap = async (
  featuresArr: Array<olFeature>,
  drawnFeature: olFeature<Geometry>,
  selectedLayer: VectorImageLayer<VectorSource> | null,
  overrideOverlap: boolean = false
) => {
  const overlapWorker = initOverlapWorker();
  const chunks: olFeature<Geometry>[][] = [];

  for (let i = 0; i < featuresArr.length; i += CHUNK_SIZE) {
    chunks.push(featuresArr.slice(i, i + CHUNK_SIZE));
  }

  return new Promise((resolve, reject) => {
    let completedChunks = 0;

    overlapWorker.onmessage = e => {
      const { type, results: chunkResults, chunkIndex, error } = e.data;

      if (type === 'error') {
        reject(new Error(error));
        return;
      }

      try {
        if (overrideOverlap) {
          chunkResults.forEach((result: { index: number; geometry?: any }) => {
            const featureIndex: number = result.index + chunkIndex * CHUNK_SIZE;
            const feature: olFeature<Geometry> = featuresArr[featureIndex];

            if (feature) {
              if (result.geometry) {
                const updatedFeature: olFeature<Geometry> = GEO_JSON.readFeature(result.geometry);
                feature.setGeometry(updatedFeature.getGeometry());
              } else if (removeFeatureFromLayer) {
                removeFeatureFromLayer(feature);
              }
            }
          });
        } else {
          chunkResults.forEach((result: { type: string; geometry?: any }) => {
            if (result.type === 'update_drawn') {
              drawnFeature.setGeometry(GEO_JSON.readFeature(result.geometry).getGeometry());
            } else if (result.type === 'remove_drawn') {
              selectedLayer?.getSource()?.removeFeature(drawnFeature);
            }
          });
        }
        completedChunks++;

        if (completedChunks === chunks.length) {
          if (drawnFeature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON && selectedLayer) {
            convertFeatureGeometry(drawnFeature, selectedLayer);
          }
          resolve(true);
        }
      } catch (err) {
        reject(err);
      }
    };

    chunks.forEach((chunk, chunkIndex) => {
      const serializedFeatures = chunk.map(feature => GEO_JSON.writeFeatureObject(feature));
      const serializedDrawnFeature = GEO_JSON.writeFeatureObject(drawnFeature);

      overlapWorker.postMessage({
        features: serializedFeatures,
        drawnFeature: serializedDrawnFeature,
        overrideOverlap,
        chunkIndex
      });
    });
  });
};

export const applyOverlapSettings = async (drawnFeature: olFeature<Geometry>, overrideOverlap: boolean = false) => {
  const layers: Layer[] = globalStore.AppStore.current_layers.filter(
    (l: Layer) => l.id !== PARCEL && l.geometry_type === GEOMETRY_TYPE.POLYGON
  );

  let selectedLayer: VectorLayer<VectorSource> | null = null;
  let featuresArr: Array<olFeature> = [];
  const newAddedFeatureExtent = drawnFeature?.getGeometry()?.getExtent();

  layers.forEach(l => {
    const layer = mapObj?.getLayerById(l.id as string);
    if (layer instanceof VectorLayer) {
      featuresArr.push(...layer.getSource().getFeaturesInExtent(newAddedFeatureExtent));
      selectedLayer = mapObj?.getLayerById(globalStore.AppStore.selectedLayerID) as VectorLayer<VectorSource>;
    }
  });

  featuresArr = featuresArr.filter(feat => feat !== drawnFeature);

  if (featuresArr.length) {
    await getFeaturesAfterOverlap(featuresArr, drawnFeature, selectedLayer, overrideOverlap);
  }
};

export const triggerOverrideOverlap = (feature: olFeature<Geometry>) => {
  const { override_overlap = false, avoid_overlap } = globalStore.AppStore.tool;
  if (override_overlap || avoid_overlap) applyOverlapSettings(feature, override_overlap);
};

export const drawStyle = (id: string, drawWidth = 3, color?: string) => {
  return new Style({
    stroke: new Stroke({
      color: getLayerType(id)?.color || color,
      width: drawWidth
    }),
    image: new Circle({
      radius: 7,
      stroke: new Stroke({
        color: 'skyblue'
      })
    })
  });
};

export const getAllFeaturesSource = () => {
  if (!mapObj.map) return;

  const layers = mapObj.map.getLayers().getArray();
  const combinedFeatures: olFeature[] = [];

  layers.forEach(layer => {
    const geomType = layer?.get('geometryType');
    const isParcel = layer?.get('id') === 'parcel_layer';
    if (!isParcel && (geomType === GEOMETRY_TYPE.POLYGON || geomType === GEOMETRY_TYPE.LINESTRING)) {
      const features = (layer as VectorLayer<VectorSource>)?.getSource()?.getFeatures() || [];
      combinedFeatures.push(...features);
    }
  });

  const combinedSource = new olVectorSource({
    features: combinedFeatures
  });

  return combinedSource;
};

const getResult = (result: boolean, isPresent: boolean, operation: number) => {
  return operation === LogicalOperation.AND ? result && isPresent : result || isPresent;
};

export const isGeomOrFeatureVisible = (tags_info: TagsInfoType, query: FilterQuery) => {
  const { tagsInfo, operation } = query || {};
  const filteringTagsInfo = tagsInfo || {};

  let result = operation === LogicalOperation.AND;
  if (!Object.keys(filteringTagsInfo)?.length) {
    return true;
  }
  Object.keys(filteringTagsInfo).forEach(filtering_TT_ID => {
    if (operation === LogicalOperation.AND && !result) return;
    if (operation === LogicalOperation.OR && result) return;

    let isPresent = false;
    if (tags_info.hasOwnProperty(filtering_TT_ID)) {
      isPresent = true;
      const queryTagIds = Object.keys(filteringTagsInfo[filtering_TT_ID] || {});
      if (queryTagIds?.length) {
        const geomTagId = tags_info[filtering_TT_ID]?.tagId;
        if (geomTagId) {
          isPresent = filteringTagsInfo[filtering_TT_ID].hasOwnProperty(geomTagId);

          const querySubtags = filteringTagsInfo[filtering_TT_ID][geomTagId];
          if (querySubtags?.length) {
            const geomSubtagId = tags_info[filtering_TT_ID]?.subtagId;

            if (geomSubtagId) {
              isPresent = querySubtags.includes(geomSubtagId);
            } else {
              isPresent = false;
            }
          }
        } else {
          isPresent = false;
        }
      }
    }

    result = getResult(result, isPresent, operation);
  });
  return result;
};
// export const getFilteredGeoms = ({ geoms = [], featureTags }: any) => {
//   const taggingFilterQuery = useTags.getState()?.filterQuery;
//   const taggingDispatch = useTags.getState()?.dispatch;
//   const layerId = geoms[0]?.properties?.vector_layer_id;

type FilteredGeometryType = {
  [featureId: string]: { type: string; features: Feature[]; default_tags?: TagsInfoType; geometry_type?: number };
};

export const filterCb = (featureList: { results: Layer[] }) => {
  let visibleGeometries: FilteredGeometryType = {};
  let hiddenGeometries: FilteredGeometryType = {};

  featureList?.results?.forEach(feature => {
    const geoms = feature?.original_json?.features || [];
    const featureTags = feature?.default_tags as TagsInfoType;
    const { visibleGeometries: filteredGeoms, hiddenGeometries: unFilteredGeoms } = getFilteredGeometries({
      geoms,
      featureTags
    });
    if (feature.id) {
      visibleGeometries = {
        ...visibleGeometries,
        [feature.id]: {
          type: 'FeatureCollection',
          features: filteredGeoms || [],
          default_tags: feature?.default_tags,
          geometry_type: feature?.geometry_type
        }
      };
      hiddenGeometries = {
        ...hiddenGeometries,
        [feature.id]: {
          type: 'FeatureCollection',
          features: unFilteredGeoms || [],
          default_tags: feature?.default_tags,
          geometry_type: feature?.geometry_type
        }
      };
    }
  });

  return {
    visibleGeometries,
    hiddenGeometries
  };
};

export const getFilteredGeometries = ({ geoms = [], featureTags }: { geoms: Feature[]; featureTags: TagsInfoType }) => {
  const taggingFilterQuery = useTags.getState()?.filterQuery;
  const layerId = geoms[0]?.properties?.vector_layer_id as string;

  const filteredGeoms: Feature[] = [];
  const unFilteredGeoms: { [layerId: string]: Feature[] } = {};
  const { type } = taggingFilterQuery;

  if (type === FilterBy.GROUP) {
    const res = isGeomOrFeatureVisible(featureTags, taggingFilterQuery);
    // all geoms of visible layer will be added to map
    if (res) {
      filteredGeoms.push(...geoms);
    } else {
      // all geoms of hidden layer will be removed from map
      unFilteredGeoms[layerId] = geoms;
    }
  } else {
    geoms.forEach(geom => {
      const geomTags = geom?.properties?.tags_info || {};
      let res;
      const resOnTagLevel = isGeomOrFeatureVisible(geomTags, taggingFilterQuery);
      if (taggingFilterQuery.type === FilterBy.TAG) {
        res = resOnTagLevel;
      } else if (taggingFilterQuery.type === FilterBy.BOTH) {
        const mergedTagsInfo = mergeFeatAndGeomTags(featureTags, geomTags);
        const resOnBothLevel = isGeomOrFeatureVisible(mergedTagsInfo, taggingFilterQuery);
        res = resOnTagLevel || resOnBothLevel;
      }

      if (res) {
        filteredGeoms.push(geom);
      } else if (unFilteredGeoms[layerId]) {
        unFilteredGeoms[layerId].push(geom);
      } else {
        unFilteredGeoms[layerId] = [geom];
      }
    });
  }
  return {
    visibleGeometries: filteredGeoms,
    hiddenGeometries: unFilteredGeoms[layerId]
  };
};

export const reselectFeatures = (selectIns: Select) => {
  const mapObj = useMapStore.getState()?.mapObj;
  const selectedToolId = useMapStore.getState()?.tool?.tool_id;
  const taggingDispatch = useTags.getState()?.dispatch;

  if (mapObj && selectIns) {
    const selectedGeomIds: (string | number)[] = [];
    const layersNewPayload: string[] = [];
    const layerIds: Set<string> = new Set();

    selectIns
      ?.getFeatures()
      ?.getArray()
      .forEach((feat: olFeature) => {
        if (feat?.getId()) selectedGeomIds.push(feat.getId() as string | number);
        if (feat?.getProperties()?.vector_layer_id) layerIds.add(feat?.getProperties()?.vector_layer_id);
      });

    const selectedLayerIds = Array.from(layerIds);
    const payload: olFeature<Geometry>[] = [];
    selectIns.getFeatures().clear();
    selectedLayerIds.forEach(layerId => {
      const olLayer: VectorLayer<VectorSource> = mapObj.getLayerById(layerId);
      if (olLayer) {
        const selected = olLayer
          ?.getSource()
          ?.getFeatures()
          ?.filter(feat => selectedGeomIds.includes(feat.getId() as string | number));
        if (selected?.length) {
          layersNewPayload.push(layerId);
          payload.push(...selected);
        }
      }
    });
    selectIns.getFeatures().extend(payload);
    taggingDispatch({
      type: taggingActions.SET_SELECTED_FEATURES,
      payload: selectIns.getFeatures()?.getArray()
    });
    if (selectedToolId === TOOL_TYPE.GROUP_TAG) {
      taggingDispatch({
        type: taggingActions.SET_SELECTED_LAYERS,
        payload: layersNewPayload
      });
    }
  }
};

export const getBufferFeaturesOfParent = (layer_type: string, featureId: string) => {
  const bufferFeatures: olFeature<Geometry>[] = [];
  const bufferLayerSource = (mapObj?.getLayerById(`buffer_${layer_type}`) as VectorLayer<VectorSource>)?.getSource();

  bufferLayerSource?.getFeatures()?.forEach(f => {
    const parentFeatureId = f.get('parent_id');

    if (parentFeatureId) {
      if (parentFeatureId === featureId) bufferFeatures.push(f);
    }
  });

  return { bufferFeatures, bufferLayerSource };
};

export const addBufferFeature = (
  featureParent: olFeature,
  layer_type: string,
  buffer_layer: VectorLayer<VectorSource>,
  bufferType: number,
  setCurrentLayers: (id: string, geojson: any) => void,
  bufferValue: number,
  avoidFeatureOverlap: boolean,
  layerName: string,
  includeHoles: boolean
) => {
  try {
    const parent_id = featureParent?.getId() as string;

    if (includeHoles) {
      addBufferWithHoles({
        feature: GEO_JSON.writeFeatureObject(featureParent),
        bufferValue,
        geomType: featureParent.getGeometry()?.getType() as string,
        buffer_layer,
        bufferType,
        parent_id,
        setCurrentLayers,
        avoidFeatureOverlap,
        layerName
      });
    } else {
      createOuterBuffer({
        buffer_layer,
        bufferType,
        bufferValue,
        parent_id,
        featureParent,
        setCurrentLayers,
        avoidFeatureOverlap,
        layerName
      });
    }
  } catch (e) {
    showToast(`Error adding buffer for feature in ${layer_type}`, 'error');
    highlightFeature(featureParent);
  }
};

interface addBufferWithHolesProps {
  feature: GeoJSONFeature;
  bufferValue: number;
  geomType: string;
  buffer_layer: VectorLayer<VectorSource> | undefined;
  bufferType: number;
  parent_id: string;
  featureParent?: olFeature;
  setCurrentLayers: (id: string, geojson: any) => void;
  avoidFeatureOverlap: boolean;
  layerName: string;
}

const addBufferWithHoles = ({
  feature,
  bufferValue,
  geomType,
  buffer_layer,
  bufferType,
  parent_id,
  setCurrentLayers,
  avoidFeatureOverlap,
  layerName
}: addBufferWithHolesProps) => {
  bufferValue = bufferType === BUFFER_TYPE.NEGATIVE ? -bufferValue : bufferValue;
  const turfbuffer = buffer(feature, bufferValue, { units: 'feet' });

  if (geomType === GEOMETRY_TYPES.POLYGON && !(bufferType === BUFFER_TYPE.NEGATIVE)) {
    switch (bufferType) {
      case BUFFER_TYPE.POSITIVE:
      case BUFFER_TYPE.BOTH: {
        const parentCoords = feature.geometry.coordinates;
        const turfCoords = turfbuffer.geometry.coordinates;

        // Iterate over each set of coordinates and create separate features. We find the overlapping
        // hole with the created buffer so that we can create a positive buffer ring for holes
        for (let idx = 0; idx < turfCoords.length; idx++) {
          const matchingCoords = getOverlappingCoords(parentCoords, turfCoords, idx);

          const feat1 = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [turfCoords[idx]]
            }
          };
          const feat2 = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [idx == 0 ? parentCoords[idx] : matchingCoords]
            }
          };

          let bufferFeature = GEO_JSON.readFeature(feat1);
          const parentFeature = GEO_JSON.readFeature(feat2);

          const geojson1: turfFeature<Polygon | MultiPolygon> = GEO_JSON.writeFeatureObject(bufferFeature);
          const geojson2: turfFeature<Polygon | MultiPolygon> = GEO_JSON.writeFeatureObject(parentFeature);

          let diff;
          if (idx == 0) {
            diff = difference(geojson1, geojson2);
          } else {
            diff = difference(geojson2, geojson1);
          }
          bufferFeature = GEO_JSON.readFeature(diff);
          bufferFeature?.set('parent_id', parent_id);
          bufferFeature?.set('vector_layer_id', layerName);
          bufferFeature?.set('temp_layer', true);
          buffer_layer?.getSource()?.addFeature(bufferFeature);

          if (avoidFeatureOverlap) applyOverlapSettings(bufferFeature);
        }

        // In case of both buffer type, add the negative buffer as well
        if (bufferType === BUFFER_TYPE.BOTH)
          addBufferWithHoles({
            feature,
            bufferValue,
            geomType,
            buffer_layer,
            bufferType: BUFFER_TYPE.NEGATIVE,
            parent_id,
            setCurrentLayers,
            avoidFeatureOverlap,
            layerName
          });
        break;
      }
      default:
        break;
    }
  } else {
    const bufferFeature = GEO_JSON.readFeature(turfbuffer);
    bufferFeature?.set('parent_id', parent_id);
    bufferFeature?.set('vector_layer_id', layerName);
    bufferFeature?.set('temp_layer', true);
    buffer_layer?.getSource()?.addFeature(bufferFeature);

    if (avoidFeatureOverlap) applyOverlapSettings(bufferFeature);
  }

  const featureCollection = GEO_JSON.writeFeaturesObject(
    buffer_layer?.getSource()?.getFeatures() as olFeature<Geometry>[]
  );
  setCurrentLayers(layerName, featureCollection);
};

const getOverlappingCoords = (
  parentCoords: Position[][],
  turfCoords: Position[][],
  idx: number
): Position[] | undefined => {
  return parentCoords.find((coord, index: number) => {
    if (index == 0) return false;

    const coords1 = {
      type: 'Polygon',
      coordinates: [coord]
    };
    const coords2 = {
      type: 'Polygon',
      coordinates: [turfCoords[idx]]
    };

    const isOverlapping = booleanIntersects(coords1, coords2);
    return isOverlapping;
  });
};

interface createOuterBufferProps {
  buffer_layer: VectorLayer<VectorSource>;
  bufferType: number;
  bufferValue: number;
  parent_id: string;
  featureParent: olFeature;
  setCurrentLayers: (id: string, geojson: GeoJSONFeature) => void;
  avoidFeatureOverlap: boolean;
  layerName: string;
}

const createOuterBuffer = ({
  buffer_layer,
  bufferType,
  bufferValue,
  parent_id,
  featureParent,
  setCurrentLayers,
  avoidFeatureOverlap,
  layerName
}: createOuterBufferProps) => {
  const modifiedFeatures: olFeature<Geometry>[] = [];
  const polygonGeometry = featureParent.getGeometry();
  const geomType = polygonGeometry?.getType();

  if (geomType !== GEOMETRY_TYPES.POLYGON) {
    const newFeature = new olFeature(polygonGeometry);
    modifiedFeatures.push(newFeature);
  } else {
    // Get the coordinates of the exterior ring (outer boundary)
    const exteriorRingCoordinates = (polygonGeometry as olPolygon)?.getLinearRing(0)?.getCoordinates();
    // Create a LineString geometry using the exterior ring coordinates
    const lineStringGeometry = new LineString(exteriorRingCoordinates as Coordinate[]);
    // Create a new feature with the LineString geometry
    const lineStringFeature = new olFeature(lineStringGeometry);
    modifiedFeatures.push(lineStringFeature);
  }

  const geojson = GEO_JSON.writeFeaturesObject(modifiedFeatures);
  geojson.features?.forEach((feature: GeoJSONFeature) => {
    addBufferWithoutHoles({
      feature,
      bufferValue,
      geomType: geomType as string,
      buffer_layer,
      bufferType,
      parent_id,
      featureParent,
      setCurrentLayers,
      avoidFeatureOverlap,
      layerName
    });
  });
};

const addBufferWithoutHoles = ({
  feature,
  bufferValue,
  geomType,
  buffer_layer,
  bufferType,
  parent_id,
  featureParent,
  setCurrentLayers,
  avoidFeatureOverlap,
  layerName
}: addBufferWithHolesProps) => {
  const turfbuffer = buffer(feature, bufferValue, { units: 'feet' });

  if (geomType === GEOMETRY_TYPES.POLYGON) {
    if (bufferType === BUFFER_TYPE.POSITIVE) {
      turfbuffer.geometry.coordinates = [turfbuffer.geometry.coordinates[0]];
    } else if (bufferType === BUFFER_TYPE.NEGATIVE) {
      turfbuffer.geometry.coordinates.shift();
      // Iterate over each set of coordinates and create separate features
      turfbuffer.geometry.coordinates.forEach(coords => {
        const turfbuffer = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [coords]
          }
        };

        addBufferFeatureToLayer({
          turfbuffer: turfbuffer as turfFeature<Polygon>,
          parent_id,
          layerName,
          geomType,
          bufferType,
          featureParent: featureParent as olFeature,
          avoidFeatureOverlap,
          buffer_layer,
          setCurrentLayers
        });
      });

      return;
    }
  }

  addBufferFeatureToLayer({
    turfbuffer,
    parent_id,
    layerName,
    geomType,
    bufferType,
    featureParent: featureParent as olFeature,
    avoidFeatureOverlap,
    buffer_layer,
    setCurrentLayers
  });
};

interface addBufferFeatureToLayerProps {
  turfbuffer: turfFeature<Polygon, Properties>;
  parent_id: string;
  layerName: string;
  geomType: string;
  bufferType: number;
  featureParent: olFeature;
  avoidFeatureOverlap: boolean;
  buffer_layer: VectorLayer<VectorSource> | undefined;
  setCurrentLayers: (id: string, geojson: any) => void;
}

const addBufferFeatureToLayer = ({
  turfbuffer,
  parent_id,
  layerName,
  geomType,
  bufferType,
  featureParent,
  avoidFeatureOverlap,
  buffer_layer,
  setCurrentLayers
}: addBufferFeatureToLayerProps) => {
  let bufferFeature = GEO_JSON.readFeature(turfbuffer);
  bufferFeature?.set('parent_id', parent_id);
  bufferFeature?.set('vector_layer_id', layerName);
  bufferFeature?.set('temp_layer', layerName);

  if (geomType === GEOMETRY_TYPES.POLYGON) {
    switch (bufferType) {
      case BUFFER_TYPE.BOTH:
      case BUFFER_TYPE.NEGATIVE:
        break;
      case BUFFER_TYPE.POSITIVE:
        const geojson1 = GEO_JSON.writeFeatureObject(bufferFeature);
        const geojson2 = GEO_JSON.writeFeatureObject(featureParent);
        // Final feature's only outer ring must be considered so that any inner rings are
        // removed from the buffer feature
        geojson2.geometry.coordinates = [geojson2.geometry.coordinates[0]];
        const diff = difference(geojson1, geojson2);
        bufferFeature = GEO_JSON.readFeature(diff);
    }
  }
  buffer_layer?.getSource()?.addFeature(bufferFeature);

  if (avoidFeatureOverlap) applyOverlapSettings(bufferFeature);

  const featureCollection = GEO_JSON.writeFeaturesObject(
    buffer_layer?.getSource()?.getFeatures() as olFeature<Geometry>[]
  );
  setCurrentLayers(layerName, featureCollection);
};

export const getTileType = (tile_server_url: string) => {
  switch (true) {
    case tile_server_url.includes('securewatch.maxar.com'):
      return 'MAXAR';
    case tile_server_url.includes('api.nearmap.com'):
      return 'NEARMAP';
    case tile_server_url.includes('api.mapbox.com'):
      return 'MAPBOX';
    default:
      return 'GSAT'; // Default case
  }
};

export const regenerateMeasurements = async (geojson: any, scale: number) => {
  const output = await asyncRun(blueprintShapelyScript, {
    layers: geojson,
    scale: scale ?? 1
  });

  return output;
};

export const isTypicalLayer = (layer: any) => {
  if (!layer) return false;
  return layer?.geometry_type === GEOMETRY_TYPES_FEATURE.TYPICAL;
};

export const getOS = () => {
  const userAgent = typeof window !== 'undefined' ? navigator.userAgent : 'Win';

  if (userAgent.includes('Win')) {
    return 'Windows';
  }
  if (userAgent.includes('Mac')) {
    return 'MacOS';
  }
  if (userAgent.includes('X11') || userAgent.includes('Linux')) {
    return 'Linux';
  }
  if (userAgent.includes('Android')) {
    return 'Android';
  }
  if (userAgent.includes('like Mac')) {
    return 'iOS';
  }
  return 'Unknown OS';
};
