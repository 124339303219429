import { Draw, Interaction } from 'ol/interaction';
import { GEOMETRY_TYPES } from 'woodpecker';
import { Type } from 'ol/geom/Geometry';
import { generateUniqueID, isValidLineString } from 'macaw';
import { LineString } from 'ol/geom';
import * as turf from '@turf/turf';
import { showToast } from 'ui';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { isOutOfExtent } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { labelStyle, polylineStyle } from '../../../hooks/tools/helpers/styles';
import { formatLength } from '../../../hooks/tools/helpers';
import { ZERO_LENGTH } from '../../../hooks/tools/helpers/constants';

class AddCurvedLine extends ToolAbstract {
  private mapObj: MapBase;

  private draw: Draw | null = null;

  private snap: Interaction[] | null = null;

  private listener: any = null;

  private layer: any = null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
    this.listener = null;
    this.layer = null;
  }

  /**
   * Initializes the tool for drawing polygons on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string): void {
    this.off();

    this.layer = this.mapObj.getLayerById(id);

    if (this.layer) {
      const source = this.layer.getSource();

      this.draw = new Draw({
        source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => {
          return this.styleFunction(feature);
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        minPoints: 2,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        geometryFunction: this.geometryFunction
      });
      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawend', this.onDrawEnd);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  styleFunction = (feature: any) => {
    const styles = [...polylineStyle];
    const { dpi, scale } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING && scale !== null) {
      const label = formatLength(geometry, dpi, scale || 0);
      if (label !== ZERO_LENGTH) {
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(geometry);
        _labelStyle.getText().setText(label);
        styles.push(_labelStyle);
      }
    }
    return styles;
  };

  /**
   * Handles key down events, specifically removing the last point from the draw interaction if the Backspace key is pressed,
   * and finishing the drawing if the Space key is pressed.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  keyDownHandler = (e: KeyboardEvent): void => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  onDrawEnd = (e: any) => {
    if (isValidLineString(e.feature)) {
      const unq_id = generateUniqueID('curve_polyline');
      e.feature.setId(unq_id);
      setTimeout(() => {
        undoRedoPush();
      }, 0);
    } else {
      showToast('Invalid feature', 'error', {
        position: 'top-center',
        hideProgressBar: false
      });
      setTimeout(() => {
        this.layer.getSource().removeFeature(e.feature);
      }, 0);
    }
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new LineString(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }

    try {
      if (coordinates.length >= 2) {
        const turfLine = turf.lineString(coordinates);
        const curvedLine = turf.bezierSpline(turfLine);
        const curveCoordinates = curvedLine.geometry.coordinates;
        geometry.setCoordinates(curveCoordinates);
      }
    } catch (error) {
      // Intentionally left blank because the error can be safely ignored
    }

    return geometry;
  };

  /**
   * Turns off the draw interaction by removing it from the map, removing the snap interaction, and removing event listeners.
   */
  off(): void {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddCurvedLine;
