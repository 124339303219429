import { Select, DragBox } from 'ol/interaction';
import { click, shiftKeyOnly, all } from 'ol/events/condition';
import { unByKey } from 'ol/Observable';
import { multiSelectStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import Feature from 'ol/Feature';
import { Overlay } from 'ol';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { isOutOfExtent } from '../../../helpers/helpers';
import { Fill, Stroke, Style } from 'ol/style';
import { GEOMETRY_TYPE, HAND_TOOL_LAYER } from 'woodpecker';
import { getExtentMidCoordinate, getExtentOfFeatures } from '../../../hooks/tools/helpers/utils';
import { Coordinate } from 'ol/coordinate';
import { Extent, containsExtent } from 'ol/extent';
import { addOverlay } from '../../../hooks/helpers';
import { Polygon } from 'ol/geom';
import { TOOL_TYPE } from '../constants';

/**
 * Custom hook for handling feature selection on a map.
 * Manages interactions for selecting and dragging features,
 * as well as handling keyboard events for attribute changes and deletion.
 */

function isPointInsideBbox(point: Coordinate, bbox: Extent) {
  var minX = Math.min(bbox[0], bbox[2]);
  var minY = Math.min(bbox[1], bbox[3]);
  var maxX = Math.max(bbox[0], bbox[2]);
  var maxY = Math.max(bbox[1], bbox[3]);

  return point[0] >= minX && point[0] <= maxX && point[1] >= minY && point[1] <= maxY;
}

const checkIsHandToolLayer = (feature: any) => {
  const vectorLayerId = feature?.getProperties()?.vector_layer_id || '';
  return vectorLayerId === HAND_TOOL_LAYER;
};

class MultiSelectTool extends ToolAbstract {
  private mapObj: MapBase;
  private select: Select | null;
  private layer: any;
  private drag: DragBox | null;
  private key: any;
  private boundary: VectorImageLayer<VectorSource> | null;
  private toolOverlay: Overlay | null;
  private newFeature: Feature | null;

  private constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.drag = null;
    this.layer = null;
    this.key = null;
    this.boundary = null;
    this.toolOverlay = null;
    this.newFeature = null;
  }

  init(id: string) {
    this.off();
    this.layer = this.mapObj?.getLayerById(id);

    if (this.layer) {
      this.select = new Select({
        multi: false,
        condition: (e: any) => {
          if (isOutOfExtent(e, this.mapObj?.map, false)) {
            return false;
          }
          return click(e || all(shiftKeyOnly, click));
        },
        filter: (feature: any, layer: any) => {
          if (
            layer?.get('id') === 'select_layer' ||
            feature?.getProperties()?.excluded ||
            checkIsHandToolLayer(feature)
          )
            return false;

          return true;
        },
        style: (e: any) => {
          const featureId = e.id_;
          let layerStyle: Style | null = null;
          let layerGeometry: string | null = '';
          let isAbstract: boolean = false;
          let isTypical: boolean = false;
          let isEnable: boolean = false;

          this.mapObj?.map?.getAllLayers().forEach((layer: any) => {
            // if (layer?.get("x_source") === "layer_panel") {
            if (layer?.getSource()?.forEachFeature) {
              layer?.getSource().forEachFeature((feature: Feature) => {
                // @ts-ignore: Unreachable code error
                if (feature?.id_ === featureId) {
                  const vector_layer_id = feature.getProperties()['vector_layer_id'];
                  layerStyle = layer?.getStyle();
                  layerGeometry = feature.getGeometry()?.getType() || null;
                  if (
                    !!globalStore?.AppStore?.layers?.has(vector_layer_id) &&
                    globalStore?.AppStore?.layers?.get(vector_layer_id).geometry_type === GEOMETRY_TYPE.ABSTRACT
                  )
                    isAbstract = true;
                  if (
                    !!globalStore?.AppStore?.layers?.has(vector_layer_id) &&
                    globalStore?.AppStore?.layers?.get(vector_layer_id).geometry_type === GEOMETRY_TYPE.TYPICAL
                  )
                    isTypical = true;
                  isEnable = true;
                }
              });
            }
          });

          if (isEnable) {
            return multiSelectStyle(layerStyle, layerGeometry, isAbstract, isTypical);
          }
          return [];
        }
      });
      this.mapObj.map?.addInteraction(this.select);
      this.toolOverlay = globalStore?.AppStore?.toolBarOverlay;
      this.drag = new DragBox();
      this.mapObj.map?.addInteraction(this.drag);
      globalStore?.AppStore?.updateState({ selectObjRef: this.select });

      this.drag.on('boxend', e => {
        const extent = this.drag?.getGeometry().getExtent();
        const isShift = e.mapBrowserEvent.originalEvent.shiftKey;
        if (!isShift) {
          const featuresToBeRemoved: Feature[] = [];
          this.select
            ?.getFeatures()
            .getArray()
            ?.forEach((feature: Feature) => {
              featuresToBeRemoved.push(feature);
            });
          featuresToBeRemoved.forEach(feature => {
            this.select?.getFeatures().remove(feature);
          });
        }

        this.mapObj?.map?.getAllLayers().forEach((layer: any) => {
          const isVisible = layer?.getVisible();
          if (layer?.getSource()?.forEachFeatureIntersectingExtent && isVisible)
            layer?.getSource()?.forEachFeatureIntersectingExtent(extent, (feature: Feature) => {
              this.select?.getFeatures().remove(feature);
              const shouldIgnoreThisFeature = !!feature?.getProperties()?.excluded || checkIsHandToolLayer(feature);
              if (!shouldIgnoreThisFeature) this.select?.getFeatures().push(feature);
            });
        });
        this.select?.dispatchEvent('select');
      });

      this.key = this.mapObj.map?.on('singleclick', e => {
        const projectionExtent = this.mapObj?.map?.getView().getProjection().getExtent();
        const mouseCoordinate = e.coordinate;
        const isPointInside = isPointInsideBbox(mouseCoordinate, projectionExtent as Extent);
        if (!isPointInside) return;
        const clickedLayer = this.mapObj?.map?.forEachFeatureAtPixel(e.pixel, function (feature: any, layer: any) {
          return layer;
        });
        if (!clickedLayer) {
          this.emptyFeatureArray();
        }
      });

      this.select?.on('select', this.handleSelect);
    }

    this.boundary = new VectorImageLayer({
      // @ts-ignore id is private
      id: 'select_layer',
      source: new VectorSource({
        wrapX: false
      }),
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(255, 165, 0, 1)',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(255, 165, 0, 0.1)'
        })
      })
    });
    this.mapObj?.map?.addLayer(this.boundary);
    this.mapObj?.map?.getView().on('change', this.handleToolbarPosition);
  }

  handleToolbarPosition = () => {
    if (globalStore?.AppStore?.toolBarOverlay) {
      const element = globalStore?.AppStore?.toolBarOverlay?.getElement() as HTMLElement;

      if (element?.style?.display !== 'none') {
        // Get map and overlay extents
        const mapViewExtent = this.mapObj?.map?.getView()?.getViewStateAndExtent()?.extent || [];

        const height = mapViewExtent[3] - mapViewExtent[1];

        // Midpoints for overlay and map extents
        const polygonExtentMid = getExtentMidCoordinate(
          this.newFeature?.getGeometry()?.getExtent() || [],
          -height / 10
        );
        const mapViewExtentMid = getExtentMidCoordinate(mapViewExtent, height / 10);

        // Initial overlay coordinates
        let toolBarOverlayCoords = polygonExtentMid[0];

        // Ensure the overlay is within the map extent
        if (!containsExtent(mapViewExtent, [...polygonExtentMid[0], ...polygonExtentMid[0]])) {
          toolBarOverlayCoords = mapViewExtentMid[0];
        }

        // Adjust for overlay element size and window boundaries
        const pixelCoords = this.mapObj?.map?.getPixelFromCoordinate(toolBarOverlayCoords);

        const buffer = 10;
        const rightPanelWidth = document.getElementById('worksheet-panel')?.offsetWidth || 320;
        const oneRemInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const overlayWidth = 42 * oneRemInPx;
        const overlayHeight = 4.5 * oneRemInPx;

        if (pixelCoords) {
          let adjustedX = pixelCoords[0];
          let adjustedY = pixelCoords[1];

          // Adjust if overlay exceeds viewport bounds
          if (pixelCoords[0] + overlayWidth + buffer + rightPanelWidth > window.innerWidth) {
            adjustedX = window.innerWidth - overlayWidth - buffer - rightPanelWidth;
          } else if (pixelCoords[0] < buffer) {
            adjustedX = buffer;
          }

          if (pixelCoords[1] + overlayHeight + buffer > window.innerHeight) {
            adjustedY = window.innerHeight - overlayHeight - buffer;
          } else if (pixelCoords[1] < buffer) {
            adjustedY = buffer;
          }

          // Update the coordinates back to map coordinates
          const adjustedCoords = this.mapObj.map?.getCoordinateFromPixel([adjustedX, adjustedY]);

          addOverlay(this.mapObj?.map, element, adjustedCoords, globalStore?.AppStore?.toolBarOverlay);
        }
      }
    }
  };

  handleOverlay = (tempArr: Feature[]) => {
    const element = this.toolOverlay?.getElement();
    const source = this.boundary?.getSource();
    source?.clear();
    globalStore?.AppStore?.updateState({
      selectedFeatures: [...(this.select?.getFeatures()?.getArray() || [])]
    });
    this.newFeature = null;
    if (tempArr.length) {
      if (element) element.style.display = 'flex';

      const polygon = new Polygon([getExtentOfFeatures(tempArr)]);
      const newFeature = new Feature({
        geometry: polygon
      });
      newFeature.setProperties({
        excluded: true
      });
      this.newFeature = newFeature;
      source?.addFeature(newFeature);
      this.handleToolbarPosition();
    } else {
      if (element) {
        element.style.display = 'none';
      }
      this.toolOverlay?.setPosition(undefined);
    }
  };

  getCoordinatesArray = (feature: Feature) => {
    // @ts-ignore: Unreachable code error
    const fCoordinates = feature.getGeometry()?.getCoordinates();
    if (feature.getGeometry()?.getType() === 'Point') {
      return [fCoordinates];
    } else if (feature.getGeometry()?.getType() === 'Polygon') {
      return fCoordinates[0];
    } else if (feature.getGeometry()?.getType() === 'LineString') {
      return fCoordinates;
    } else {
      return [fCoordinates];
    }
  };

  handleSelect = () => {
    let ids: string[] = [];
    let tempArr: Feature[] = [];
    this.select
      ?.getFeatures()
      .getArray()
      .forEach((feature: Feature) => {
        const layerId = feature?.getProperties()?.['vector_layer_id'];
        const layer = this.mapObj.getLayerById(layerId);
        const isVisible = layer?.getVisible();
        if (!isVisible) return;
        if (!ids.includes(layerId)) ids.push(layerId);
        tempArr.push(feature);
      });
    this.handleOverlay(tempArr);
  };

  emptyFeatureArray() {
    if (this.select) {
      this.select.getFeatures().clear();
    }
  }

  /**
   * Disables the feature selection interaction and cleans up event listeners.
   */
  off = () => {
    if (
      globalStore.AppStore.tool.tool_id === TOOL_TYPE.COPY_AND_MOVE ||
      globalStore.AppStore.tool.tool_id === TOOL_TYPE.MOVE ||
      globalStore.AppStore.tool.is_action
    ) {
      globalStore.AppStore.setSelectedFeatures(
        globalStore.AppStore.selectedFeatures?.length ? [...globalStore.AppStore.selectedFeatures] : []
      );
    }

    this.emptyFeatureArray();
    this?.select && this.mapObj?.map?.removeInteraction(this.select);
    this.drag && this.mapObj?.map?.removeInteraction(this.drag);
    if (this.boundary) this.mapObj?.map?.removeLayer(this.boundary);
    this.boundary = null;
    if (this.toolOverlay) {
      const element = this.toolOverlay?.getElement();
      if (element) element.style.display = 'none';
      this.toolOverlay.setPosition(undefined);
      this.mapObj?.map?.removeOverlay(this.toolOverlay);
    }
    this.mapObj?.map?.getView().un('change', this.handleToolbarPosition);
    this.toolOverlay = null;
    unByKey(this.key);
    globalStore?.AppStore?.updateState({
      selectedFeaturesLayer: new Set<string>()
    });
  };
}

export default MultiSelectTool;
