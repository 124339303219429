import { captureException } from '@sentry/minimal';

import message from 'antd/es/message';

import GeoJSON from 'ol/format/GeoJSON';
import Modify from 'ol/interaction/Modify';
import Draw from 'ol/interaction/Draw';
import { containsExtent } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { polygon } from '@turf/helpers';
import kinks from '@turf/kinks';
import booleanIntersects from '@turf/boolean-intersects';

import { MODIFY_STYLE, EDIT_STYLE, getDrawStyle } from '../MapBase';
import { PARCEL_NOT_PRESENT } from '../../../Constants/Messages';
import { outputMap } from '../MapInit';
import { isValidPolygon } from '../../../Utils/olutils';
import { getRequestData } from '../../../Utils/HelperFunctions';
import { MAP_LAYERS, REQUEST_STATUS_ENUM } from '../../../Constants/Constant';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';

class EditParcel extends Observer {
  areaStyleCache: $TSFixMe;

  bufferLayer: $TSFixMe;

  draw: $TSFixMe;

  hoveredFeature: $TSFixMe;

  isCompletedRequest: $TSFixMe;

  mapObj: $TSFixMe;

  modify: $TSFixMe;

  originalParcel: $TSFixMe;

  parcelStyle: $TSFixMe;

  parcelVisibility: $TSFixMe;

  select: $TSFixMe;

  parcelLayer: any;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.modify = null;
    this.draw = null;
    this.parcelStyle = null;
    this.hoveredFeature = null;
    this.bufferLayer = null;
    this.isCompletedRequest = false;
    this.areaStyleCache = [];
    this.parcelVisibility = true;
    this.originalParcel = null;
    this.parcelLayer = null;
  }

  on({ isDraw, requestStatus }: $TSFixMe) {
    if (!isDraw && !this.getFeatures().length) throw new Error('PARCEL_NOT_FOUND');
    const isCompletedRequest = requestStatus === REQUEST_STATUS_ENUM.COMPLETED;
    this.isCompletedRequest = isCompletedRequest;
    const parcelLayer = this.mapObj.getParcelLayer(true);
    this.parcelLayer = parcelLayer;
    if (!parcelLayer) return captureException(new Error('Parcel layer not found'));

    this.originalParcel = new GeoJSON().writeFeatures(parcelLayer.getSource().getFeatures());
    this.parcelStyle = parcelLayer.getStyle();
    parcelLayer.setStyle(isDraw ? EDIT_STYLE : MODIFY_STYLE);

    if (isDraw) {
      this.draw = new Draw({
        source: parcelLayer.getSource(),
        type: 'Polygon',
        style: getDrawStyle(),
        condition: e => {
          const mouseClick = e.originalEvent.button;
          if (mouseClick === 2 || mouseClick === 1) {
            return false;
          }
          return true;
        },
        snapTolerance: 1,
        ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
      });
      this.mapObj.map.addInteraction(this.draw);
      this.draw.on('drawend', this.handleDrawEnd);
      document.addEventListener('keydown', this.removeLastPointOnBack);
    } else {
      this.modify = new Modify({
        source: parcelLayer.getSource(),
        snapToPointer: true
      });
      this.mapObj.map.addInteraction(this.modify);
      this.modify.on('modifyend', this.handleChange);
    }
    this.mapObj.map.on('contextmenu', this.removePointOnRightClick);
    return null;
  }

  getImageExtent = (image: $TSFixMe) => {
    if (!image || Object.keys(image).length === 0) return null;
    return transformExtent([image.left, image.bottom, image.right, image.top], 'EPSG:4326', 'EPSG:3857');
  };

  removeLastPointOnBack = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();

    const KeyID = event.keyCode;
    if (KeyID === 8) {
      this.draw.removeLastPoint();
    }
    if (KeyID === 27) {
      this.draw.abortDrawing();
    }
  };

  removePointOnRightClick = (e: $TSFixMe) => {
    e.preventDefault();

    if (this.modify) {
      const parcelLayer = this.mapObj.getParcelLayer(true);
      const features = parcelLayer.getSource().getFeatures();
      let anyFeatureChanged = false;

      features.length &&
        features.forEach((feature: $TSFixMe) => {
          let isChanged = false;
          const coords = feature.getGeometry().getCoordinates();
          for (let i = 0; i < coords.length; i++) {
            const coord = coords[i];
            for (let j = 0; j < coord.length; j++) {
              const p0 = e.pixel;
              const p1 = this.mapObj.map.getPixelFromCoordinate(coord[j]);
              const distance = pointsDistance(p0, p1);
              // Polygon should have at least 4 points where first=last
              if (distance < 12 && coord.length > 4) {
                coord.splice(j, 1);
                // if the removed coordinate is the first coordinate of a close polygon, add second coord as the start coord
                if (j === 0) coord.push(coord[0]);
                isChanged = true;
              }
            }
          }
          if (isChanged) {
            anyFeatureChanged = true;
            feature.getGeometry().setCoordinates(coords);
          }
        });

      if (!this.isCompletedRequest && anyFeatureChanged) this.notifyObservers(TOOL_EVENT.EDIT_PARCEL);
    }
  };

  getParcelGeojson(isInitial = true) {
    const parcelLayer = this.mapObj.getParcelLayer(isInitial);
    const geojson = this.getGeojsonByLayer(parcelLayer);
    return geojson;
  }

  getGeojsonByLayer(layer: $TSFixMe) {
    return outputMap.getGeojsonByLayer(layer);
  }

  handleDrawEnd = (e: $TSFixMe) => {
    // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
    e.feature.setProperties({ layerId: getRequestData().input?.id });
    // delaying since Draw dispatches event before adding the feature to the source
    setTimeout(() => this.handleChange(e), 300);
  };

  handleChange = (e: $TSFixMe) => {
    if (!this.checkForValidPolygons(e.feature ? [e.feature] : e.features.getArray())) {
      return this.parcelLayer.getSource().removeFeature(e.feature);
    }
    this.notifyObservers(TOOL_EVENT.EDIT_PARCEL);
    return null;
  };

  checkForValidPolygons = (features: $TSFixMe) => {
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      if (!isValidPolygon(feature, false)) {
        message.error('Invalid polygon was created, please try the last edit again.');
        return false;
      }
    }
    return true;
  };

  getFeatures() {
    const parcelLayer = this.mapObj.getParcelLayer(true);
    if (!parcelLayer) return [];

    const src = parcelLayer.getSource();
    return src ? src.getFeatures() : [];
  }

  isParcelValid() {
    if (!this.hasParcelLayer()) {
      message.error(PARCEL_NOT_PRESENT);
      return false;
    }

    return true;
  }

  areHolesOverLapping(feature: $TSFixMe) {
    if (feature) {
      let isHoleOverlapping = false;

      // Array of linear rings that define the polygon.
      // The first linear ring of the array defines the outer-boundary or surface of the polygon.
      // Each subsequent linear ring defines a hole in the surface of the polygon.
      const coordinates = feature?.geometry?.coordinates;

      // Since there is maximum one hole in polygon we can return true
      if (coordinates.length <= 2) {
        return false;
      }

      for (let i = 1; i < coordinates.length; ++i) {
        for (let j = 1; j < coordinates.length; ++j) {
          if (i !== j) {
            const linearRing1 = polygon([coordinates[i]]); // Hole i
            const linearRing2 = polygon([coordinates[j]]); // Hole j
            isHoleOverlapping = isHoleOverlapping ? true : booleanIntersects(linearRing1, linearRing2);
          }
        }
      }

      return isHoleOverlapping;
    }

    return false;
  }

  isOutsideHDImagery(image: $TSFixMe) {
    const imageExtent = this.getImageExtent(image);
    const parcelExtent = this.getParceExtent();
    if (!imageExtent || !parcelExtent) return false;
    return !containsExtent(imageExtent, parcelExtent);
  }

  // Returns true if two polygons or two holes within a polygon are overlapping
  isParcelOverlap(featureGeojson: $TSFixMe) {
    try {
      let isHoleOverlapping = false; // true if two or more holes within a lot boundary are overlapping

      const features = featureGeojson?.features?.length ? featureGeojson.features : [];
      if (!features.length) return false;

      isHoleOverlapping = features.some((feature: $TSFixMe) => this.areHolesOverLapping(feature));
      if (isHoleOverlapping) {
        setTimeout(() => {
          message.error('Holes in lot boundary cannot overlap or intersect. Please redraw the hole');
        }, 0);
        return true;
      }

      const isSelfIntersecting = features.some((feature: any) => {
        return this.checkSelfIntersection(feature);
      });
      if (isSelfIntersecting) {
        setTimeout(() => {
          message.error('Invalid lot boundary! Please redraw a closed polygon chain.');
        }, 0);
        return true;
      }

      // true if two or more lot boundaries are overlapping
      const isOverlap = features.find((feature: $TSFixMe, i: $TSFixMe) =>
        features.find((val: $TSFixMe, j: $TSFixMe) => {
          if (
            i !== j &&
            booleanIntersects(polygon(feature?.geometry?.coordinates), polygon(val?.geometry?.coordinates))
          ) {
            return true;
          } else {
            return false;
          }
        })
      );
      if (isOverlap) {
        setTimeout(() => {
          message.error('Lot boundaries cannot overlap over each other.');
        }, 0);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      // happened one time, when start and end coordinates are not equal so turfPolygon throws error
      captureException(err);
      message.error('Invalid lot boundaries. Please redraw');
      return true;
    }
  }

  checkSelfIntersection = (polygon: any) => {
    const intersections = kinks(polygon);
    return intersections.features.length > 0;
  };

  hasParcelLayer() {
    const parcelLayer = this.mapObj.getParcelLayer(true);
    const src = parcelLayer?.getSource();
    const features = src?.getFeatures();
    return Boolean(features?.length);
  }

  removeParcel() {
    const parcelLayer = this.mapObj.getParcelLayer(true);
    if (parcelLayer) {
      parcelLayer.getSource().clear();
    }
  }

  getParceExtent() {
    const parcelLayer = this.mapObj.getParcelLayer();
    if (parcelLayer) {
      return parcelLayer.getSource().getExtent();
    }
    return null;
  }

  removeBufferLayer() {
    this.bufferLayer && this.mapObj.removeLayer(this.bufferLayer);
  }

  setParcelVisibility = (val: boolean, isOldParcel: boolean = false) => {
    this.parcelVisibility = val;
    outputMap.setVisibilityByName(isOldParcel ? MAP_LAYERS.OLD_PARCEL : MAP_LAYERS.PARCEL, val);
  };

  off() {
    const parcelLayer = this.mapObj.getParcelLayer(true);
    if (this.parcelStyle && parcelLayer) {
      parcelLayer.setStyle(this.parcelStyle);
    }
    this.modify && this.mapObj.map.removeInteraction(this.modify);
    this.draw && this.mapObj.map.removeInteraction(this.draw);
    document.removeEventListener('keydown', this.removeLastPointOnBack);
    this.mapObj.map.un('contextmenu', this.removePointOnRightClick);

    this.areaStyleCache = [];
    this.select = null;
    this.modify = null;
    this.draw = null;
    this.parcelStyle = null;
    this.hoveredFeature = null;
    this.bufferLayer = null;
    this.isCompletedRequest = false;
    this.parcelVisibility = true;
    this.originalParcel = null;
  }
}

export default EditParcel;

export function pointsDistance(p0: $TSFixMe, p1: $TSFixMe) {
  const dx = p0[0] - p1[0];
  const dy = p0[1] - p1[1];
  return Math.sqrt(dx * dx + dy * dy);
}
