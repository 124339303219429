import { Draw } from 'ol/interaction';
import Interaction from 'ol/interaction/Interaction';
import { GEOMETRY_TYPES, MAP_TYPE, ORTHO_ANGLE } from 'woodpecker';
import { generateUniqueID, getArc, getIntersection, isValidLineString } from 'macaw';
import { Type } from 'ol/geom/Geometry';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { showToast } from 'ui';
import { Coordinate } from 'ol/coordinate';
import { getAngle, getSnappingAngle } from 'macaw/src/getArc';
import { ZERO_LENGTH } from '../../../hooks/tools/helpers/constants';
import { formatLength } from '../../../hooks/tools/helpers';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { crossHairStyle, labelStyle, pointStyle, polylineStyle } from '../../../hooks/tools/helpers/styles';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { getAllFeaturesSource, isOutOfExtent } from '../../../helpers/helpers';
import MapBase from '../../mapLayer/mapBase';

type ArcCoordinateType = {
  coordinate: Coordinate;
  isMidPoint: boolean;
};

class AddPolyLine extends ToolAbstract {
  private mapObj: MapBase;

  draw: Draw | null;

  private snap: Interaction[] | null = null;

  private layer: any;

  private coordinateRef: { coordinate: Coordinate; isMidPoint: boolean }[];

  private drawDone: boolean;

  private prevState: any;

  private curvedCoordinatesRef: Map<any, any>;

  private didPointMoved: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
    this.curvedCoordinatesRef = new Map();
    this.drawDone = false;
    this.coordinateRef = [];
  }

  /**
   * Initializes the DrawPolyline interaction.
   * Creates a new Draw interaction with the selectedLayer's source as the target source.
   * Sets the type to "LineString", style to drawStyle(), dragVertexDelay to 0, and snapTolerance to 1.
   * Sets the condition to allow drawing only on left-click.
   * Adds the Draw interaction to the map.
   * Adds event listeners for drawend and keydown.
   */
  init = (id: string, featureTracing: boolean = false) => {
    this.layer = this.mapObj.getLayerById(id);

    if (this.layer) {
      const source = this.layer.getSource();

      const isAerial = this.mapObj.map_type === MAP_TYPE.AERIAL;
      const targetLayerSource = getAllFeaturesSource();

      this.draw = new Draw({
        source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => {
          return this.styleFunction(feature);
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        minPoints: 2,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        ...(!isAerial && { geometryFunction: this.geometryFunction }),
        trace: featureTracing,
        traceSource: targetLayerSource
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawstart', this.onDrawStart);
      this.draw?.on('drawend', this.onDrawEnd);
      this.draw?.on('drawabort', () => {
        this.resetToInitialState();
      });
      window.addEventListener('keydown', this.keyDownHandler);
    }
  };

  onDrawStart = () => {
    const updateState = globalStore.AppStore?.updateState;
    updateState({ isDrawingPolygonOrPolyline: true });
  };

  /**
   * Event handler for the keydown event.
   * Removes the last drawn point if the Backspace key is pressed.
   * Finishes the drawing if the Space key is pressed.
   * @param e - The keydown event object.
   */
  keyDownHandler = (e: any) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
      if (this.coordinateRef.length > 0) {
        this.coordinateRef.pop();
        if (this.didPointMoved) {
          this.coordinateRef.pop();
        }
      }
      this.didPointMoved = false;
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    } else if (e.code == 'KeyQ') {
      if (globalStore.AppStore.ortho_mode && globalStore.AppStore.isDrawingPolygonOrPolyline) return;
      const updateState = globalStore.AppStore?.updateState;

      updateState({ arc_mode: true, ortho_mode: false });
    } else if (e.shiftKey) {
      if (globalStore?.AppStore?.arc_mode && globalStore.AppStore.isDrawingPolygonOrPolyline) return;

      const updateState = globalStore.AppStore?.updateState;
      updateState({ ortho_mode: !globalStore.AppStore?.ortho_mode, arc_mode: false });
    }
  };

  styleFunction = (feature: Feature) => {
    const { dpi, scale } = globalStore.AppStore.worksheetParams;
    let styles = [...polylineStyle];

    if (globalStore.AppStore.ortho_mode) {
      styles = [crossHairStyle];
    }

    if (globalStore?.AppStore.arc_mode) {
      styles = [...styles, pointStyle];
    }

    const geometry = feature.getGeometry() as LineString;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING && scale !== null) {
      const segments: number[][][] = [];
      const coords = geometry?.getCoordinates();
      let i = 0;
      while (i < coords.length) {
        const coord = coords[i];
        if (this.curvedCoordinatesRef.has(JSON.stringify(coord))) {
          if (i - 1 >= 0) {
            segments.push([coords[i - 1], coord]);
          }
          const value = this.curvedCoordinatesRef.get(JSON.stringify(coord));
          const curve = [];
          while (value !== JSON.stringify(coords[i])) {
            curve.push(coords[i]);
            i++;
          }
          if (i + 1 < coords.length && value === JSON.stringify(coords[i])) {
            curve.push(coords[i + 1]);
            segments.push(curve);
            if (!this.curvedCoordinatesRef.has(JSON.stringify(coords[i + 1]))) {
              i++;
            }
          }
        } else if (i - 1 >= 0) {
          segments.push([coords[i - 1], coord]);
        }
        i++;
      }
      segments.forEach(lineCoord => {
        const segment = new LineString(lineCoord);
        const label = formatLength(segment, dpi, scale || 0);
        if (label !== ZERO_LENGTH) {
          const _labelStyle = labelStyle.clone();
          _labelStyle.setGeometry(segment);
          _labelStyle.getText().setText(label);
          if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT) styles.push(_labelStyle);
        }
      });
    }
    return styles;
  };

  onDrawEnd = (e: any) => {
    const isAerial = this.mapObj.map_type === MAP_TYPE.AERIAL;

    const isOutExtent =
      this.mapObj.map_type === MAP_TYPE.AERIAL
        ? false
        : FeatureisOutOfExtent(e.feature.getGeometry().getExtent(), this.mapObj.map);
    if (isValidLineString(e.feature) && !isOutExtent) {
      const { feature } = e;
      const source = this.layer?.getSource();

      const unq_id = generateUniqueID('polyline');
      e.feature.setId(unq_id);

      // Manually add feature to source if not added automatically
      if (source && !source.getFeatures().includes(feature)) {
        source.addFeature(feature);
      }

      setTimeout(() => {
        undoRedoPush();
      }, 0);
    } else {
      if (!isOutExtent) {
        showToast('Invalid feature', 'error', {
          position: 'top-center',
          hideProgressBar: false
        });
      }
      setTimeout(() => {
        this.layer.getSource().removeFeature(e.feature);
      }, 0);
    }
    this.resetToInitialState();
  };

  getUniqCoordArr = (coords: Coordinate[]) => {
    const map = new Map<string, boolean>();
    const newCoords: Coordinate[] = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      const key = JSON.stringify(element);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    return newCoords;
  };

  getUniqCoord = (coords: ArcCoordinateType[]) => {
    const map = new Map<string, boolean>();
    const newCoords: ArcCoordinateType[] = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      const key = JSON.stringify(element.coordinate);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    return newCoords;
  };

  getArcCoordinate = (allCoords: ArcCoordinateType[]) => {
    let coords: Coordinate[] = [];
    const geoCoords = this.getUniqCoord(allCoords);
    this.curvedCoordinatesRef = new Map<string, string>();
    for (let index = 0; index < geoCoords.length; index++) {
      const { coordinate, isMidPoint } = geoCoords[index];
      if (isMidPoint) {
        if (index + 1 < geoCoords.length) {
          const arc: Coordinate[] = getArc(
            geoCoords[index - 1].coordinate,
            coordinate,
            geoCoords[index + 1].coordinate
          );
          coords = [...coords, ...arc];
          this.curvedCoordinatesRef.set(JSON.stringify(arc[0]), JSON.stringify(arc[arc.length - 1]));
        } else coords.push(coordinate);
      } else if (index + 1 < geoCoords.length) {
        if (geoCoords[index + 1].isMidPoint === false || index === 0) {
          coords.push(coordinate);
        }
      } else coords.push(coordinate);
    }
    coords = this.getUniqCoordArr(coords);
    return coords;
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new LineString(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }
    this.didPointMoved = true;

    const points = coordinates;
    if (points?.length > 1) {
      const lastAppendedInd = this.coordinateRef.length;
      const lastClickedInd = points.length - 1;
      if (lastAppendedInd) {
        if (
          JSON.stringify(this.coordinateRef[lastAppendedInd - 1].coordinate) !==
          JSON.stringify(points[lastClickedInd - 1])
        ) {
          if (globalStore?.AppStore?.arc_mode) {
            const updateState = globalStore.AppStore?.updateState;
            if (this.coordinateRef[lastAppendedInd - 1].isMidPoint) {
              updateState({ arc_mode: false });
            }
            this.coordinateRef.push({
              coordinate: points[lastClickedInd - 1],
              isMidPoint: globalStore?.AppStore?.arc_mode
            });
            updateState({ arc_mode: false });
          } else {
            this.coordinateRef.push({
              coordinate: points[lastClickedInd - 1],
              isMidPoint: false
            });
          }
        }
      } else {
        this.coordinateRef.push({
          coordinate: points[0],
          isMidPoint: false
        });
      }

      if (this.coordinateRef.length > 1) {
        const newCoordinates: ArcCoordinateType[] = [
          ...this.coordinateRef,
          { coordinate: points[lastClickedInd], isMidPoint: false }
        ];
        const newGeometryCoord = this.getArcCoordinate(newCoordinates);
        geometry.setCoordinates(newGeometryCoord);
      }
    }

    if (globalStore.AppStore.ortho_mode) {
      const coords = geometry?.getCoordinates();
      const lastCoord = coords[coords.length - 1];
      const sourceCoord = coords[coords.length - 2];

      const angle = getSnappingAngle(getAngle(sourceCoord, lastCoord), ORTHO_ANGLE);

      const interactionCoord = getIntersection(
        {
          angle,
          coordinate: sourceCoord
        },
        {
          angle: 90,
          coordinate: lastCoord
        }
      );
      coords[coords.length - 1] = interactionCoord;
      geometry.setCoordinates(coords);
    }

    return geometry;
  };

  resetToInitialState = (resetOrthoMode = false) => {
    const updateState = globalStore.AppStore?.updateState;
    updateState({
      arc_mode: false,
      isDrawingPolygonOrPolyline: false,
      ...(resetOrthoMode ? { ortho_mode: false } : {})
    });
    this.coordinateRef = [];
  };

  /**
   * Disables the DrawPolyline interaction.
   * Removes the Draw interaction from the map.
   * Removes event listeners for drawend and keydown.
   */
  off = () => {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    this.draw?.un('drawstart', this.onDrawStart);
    this.resetToInitialState(true);
    window.removeEventListener('keydown', this.keyDownHandler);
  };
}

export default AddPolyLine;
