import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { polygon, featureCollection } from '@turf/helpers';
import clone from '@turf/clone';
import union from '@turf/union';
import { buffer } from '@turf/turf';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GEO_JSON from '../geoJson';
import removeFeatures from '../removeFeature';
import { Position } from '@turf/helpers';
import { generateUniqueID } from '../miscellaneous';
import { UNSET_PROPERTIES } from 'woodpecker';

export function turfMerge(fc: any) {
  let merged = clone(fc.features[0]);
  let poly;
  let features = fc.features;
  // Storing unmerged polygons (due to TopologyException) and adding them separately
  let unmerged: any[] = [];
  try {
    for (let i = 1, len = features.length; i < len; i++) {
      poly = features[i];
      if (poly.geometry) {
        try {
          poly = buffer(poly, 0.00001, {
            units: 'meters' // Need to reduce because when number of merges performs on single feature that will increases the actual size of the polygon
          }); //This is because the vertices between adjacent polygons do not precisely match
          merged = union(merged, poly);
        } catch (err: any) {
          if (err.name == 'TopologyException') {
            unmerged.push(poly);
          }
        }
      }
    }
    let polys: any[] = [];
    let _features;
    // union returns multipolygon when no feature is overlapped
    // convert multipolygon to polygon
    if (merged.geometry.type == 'MultiPolygon') {
      merged.geometry.coordinates.forEach(function (coords: Position[][]) {
        let poly = polygon(coords);
        polys.push(poly);
      });
      // Pushing unmerged polygons into the list
      unmerged.forEach(poly => {
        polys.push(poly);
      });
      _features = featureCollection(polys);
    } else {
      // If there are any unmerged polygons, return a featureCollection containing unmerged polygons
      if (unmerged.length > 0) {
        let polys: any[] = [];
        unmerged.forEach(poly => {
          polys.push(poly);
        });
        polys.push(merged);
        _features = featureCollection(polys);
      } else {
        _features = merged;
      }
    }
    return _features;
  } catch (err) {}
}

export const mergeFeatures = (
  features: Feature[],
  featureLayer: VectorLayer<VectorSource<Geometry>>,
  toLayer?: VectorLayer<VectorSource<Geometry>>
) => {
  const props = features[0].getProperties();
  delete props['geometry'];
  const geojson = GEO_JSON.writeFeaturesObject(features);
  let source: VectorSource | null = featureLayer.getSource();
  const merged = turfMerge(geojson);
  removeFeatures(source as VectorSource, features as any);
  const mergedGeojson = GEO_JSON.readFeatures(merged);

  mergedGeojson.forEach(f => {
    f.setProperties({ ...props });
    f.setId(generateUniqueID());
    UNSET_PROPERTIES.forEach(property => {
      f.unset(property, false);
    });
  });

  if (toLayer) {
    source = toLayer.getSource();
  }
  source?.addFeatures(mergedGeojson);
  return mergedGeojson;
};
