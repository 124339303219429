import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef } from 'react';

const getChangeParams = (prev: any, curr: any) => {
  let toUpdate = {};
  for (let [key, value] of Object?.entries(curr)) {
    if (prev?.hasOwnProperty(key)) {
      if (curr[key]?.toString() !== prev[key]?.toString()) {
        toUpdate = { ...toUpdate, [key]: curr[key] };
      }
    } else toUpdate = { ...toUpdate, [key]: curr[key] };
  }
  return toUpdate;
};

const getCurrentParams = (params: any, query: any) => {
  let curr = {};
  for (let [key, value] of Object?.entries(query)) {
    if (params?.hasOwnProperty(key)) {
      curr = { ...curr, [key]: params[key] };
    } else curr = { ...curr, [key]: '' };
  }
  return curr;
};

const useQueryFilters = (query: any, callback?: any) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const prevQuery = useRef({});
  const prevLocation = useRef<string>(pathname);

  const getAllParams = useCallback(() => {
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params = { ...params, [key]: value };
    }
    return params;
  }, [searchParams]);

  const encodeData = (data: any) => {
    let array: string[] = [];
    Object.keys(data).map(function (key) {
      if (!!data[key]?.toString()) {
        array.push([key, data[key]].map(encodeURIComponent).join('='));
        return [key, data[key]].map(encodeURIComponent).join('=');
      } else return null;
    });
    array.sort((a, b) => (a[0] < b[0] ? -1 : 1));
    return array.join('&');
  };

  const getURL = (pathname: string, params: any) => {
    const query = encodeData({ ...getAllParams(), ...params });
    return !!query ? `${pathname}?${query}` : pathname;
  };

  const setQueryParams = (toUpdate: any) => {
    const url = getURL(pathname, toUpdate);
    const location_ = getURL(pathname, getAllParams());
    if (location_ !== url) {
      setTimeout(() => {
        prevLocation.current = url;
        router.push(url);
      }, 10);
    }
  };

  const removeFilter = (queryToRemove: string[]) => {
    let params: any = {};
    queryToRemove?.map(query => {
      params = { ...params, [query]: '' };
    });
    const url = getURL(pathname, params);

    if (prevLocation?.current !== url) {
      router.push(url);
      prevLocation.current = url;
    }
  };

  useEffect(() => {
    const toUpdate = query;
    const location_ = getURL(pathname, getAllParams());
    if (Object?.entries(toUpdate)?.length && prevLocation.current === location_) {
      setQueryParams(toUpdate);
    }
    prevQuery.current = query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const current = getCurrentParams(getAllParams(), query);
    const changed = getChangeParams(query, current);
    const location_ = getURL(pathname, getAllParams());
    if (callback && Object?.entries(changed)?.length && prevLocation.current !== location_) {
      callback(changed);
    }
    prevLocation.current = location_;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    return () => {
      prevLocation.current = pathname;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allParams = useMemo(() => getAllParams(), [getAllParams]);

  return { allParams, removeFilter };
};

export const useRouterPush = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const getAllParams = useCallback(() => {
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params = { ...params, [key]: value };
    }
    return params;
  }, [searchParams]);

  const encodeData = (data: any) => {
    let array: string[] = [];
    Object.keys(data).map(function (key) {
      if (!!data[key]?.toString()) {
        array.push([key, data[key]].map(encodeURIComponent).join('='));
        return [key, data[key]].map(encodeURIComponent).join('=');
      } else return null;
    });
    array.sort((a, b) => (a[0] < b[0] ? -1 : 1));
    return array.join('&');
  };

  const getURLNAv = (pathname: string, params: any) => {
    const query = encodeData({ ...getAllParams(), ...params });
    return !!query ? `${pathname}?${query}` : pathname;
  };

  const push = (obj: any) => {
    const url = getURLNAv(pathname, obj);
    router.push(url);
  };

  const getURL = (obj: any) => {
    const url = getURLNAv(pathname, obj);
    console.log('url', url);
    return url;
  };

  return { push, getURL };
};

export default useQueryFilters;
